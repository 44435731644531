import { getJoinDateChangeWarning } from "./ParticipationHelper";
import { OnboardingTask } from "./TaskConfig/OnboardingTask";
import { FLOW } from "../../flow/flow";
import CorrectedEventsTable from "./TaskConfig/CorrectedEventsTable";

// Definitions:
//  - isPermanent: Will remove delete button when editing events
//  - canSuperDelete: if true, super admins can delete
//  - histHidden: Will hide event in history tables
//  - hiddenGroup: Will group events but not show the group in the pp events dropdown
//  - isHidden: Will hide as a choice in dropdown participation event (now a function in which we can pass params)
//  - saveWarning: Returns a warning message based on old and new event
//      - params: { oldEvent, newEvent }
//  - includeInDesc: includes the description in the status event field in member history
//  - isEnrollEvent: is an event that enrolls the person
//  - shouldHideDate: Will hide the effective date of the event in the history
//  - showJoinDate: Will show join date instead of hired date in remittance details table
//  - singleEvent: Will hide event in dropdown if event already exists in history
//  - singleEventInGroup: Will hide event in dropdown if event already exists in history
//  - isReimbursment: is an RIM event
//  - canAdd: called before adding event to the history, event wont be added if answer is false
//  - canShow: called before showing the event in the participation history
//  - showTask: will determine if it should show its related task instead of the event form edit page
//  - hideForJanOneWarning: Will not show the Jan One warning in Year End 
//  - dynamicDesc: will override the event description
//  - onlySuperAdminEdit: Only SuperAdmin users can add/edit this event. Default is `false`. Value for `inegRev` is `true`.
//  - isSpouseCheck: is an event that triggers check spouse information task
//  - extendEventEdit: use this to extend current event edit page
//  - comment: pre-populated comment for the event

/**
 * Properties:
 * - `onAdd`: on add event, add the remittance pointer (key: `employerId_period`) if provided, and add the employment pointer (key: `employerId`) if provided
 */
const withPointersOnAdd = {
    /**
     * On add event, add the remittance pointer (key: `employerId_period`) if provided, and add the employment pointer (key: `employerId`) if provided
     * @param {{event: ParticipationEvent; openRemittance: Remittance | undefined; openEmployment: Employment | undefined;}} param0 
     * @returns {ParticipationEvent}
     */
    onAdd: ({event, openRemittance, openEmployment}) =>  {
        if (openEmployment) {
            event.pointers.pushNew({
                registryKey: 'employers', 
                name: 'employer', 
                instanceKey: openEmployment.employer.keyValue, // employerId
                instance: openEmployment.employer
            });
        }
        if (openRemittance) {
            event.pointers.pushNew({
                registryKey: 'remittances', 
                name: 'remittance', 
                instanceKey: openRemittance.keyValue, // employerId_period
                instance: openRemittance
            });
        }
        return event;
    }
}

const shared = {
    active: {
        priority: 2,
        status: 'ACT', 
        groups: ['Eligibility'],
        showJoinDate: true,
        singleEventInGroup: true,
        hiddenGroup: 'NROL',
        isEnrollEvent: true,
        isPermanent: true,
        canSuperDelete: true,
        saveWarning: getJoinDateChangeWarning
    },
    ineligible: {
        priority: 1,
        status: 'NEG', 
        dynamicDesc: (event) => { 
            const employer = event.config.getEmployer(event);
            if (employer) {
                return `${event.desc} (${employer.code})`;
            }
            return event.desc;
        },
        getEmployer: (event) => { 
            let pointer = event.pointers.find(p => p.name === 'employer');
            if (pointer && pointer.isLoaded) {
                return pointer.value;
            }
        }
    },
    eligibility: {
        groups: ['Eligibility'],
        priority: 1,
        dynamicDesc: (event) => { 
            const employer = event.config.getEmployer(event);
            if (employer) return `${event.desc} (${employer.code})`;
            return event.desc;
        },
        getEmployer: (event) => { 
            let pointer = event.pointers.find(p => p.name === 'employer');
            if (pointer && pointer.isLoaded) return pointer.value;
        },
        ...withPointersOnAdd
    }
}

/*
 * IMPORTANT:
 * Make sure that the events here match the events in /src/model/membership/ParticipationEventCodes.js in the Services.
 * 
 */

export const participationEventConfigs = {
    default: { 
        isPermanent: false,
        priority: 0,
        /** Only SuperAdmin users can add/edit this event. Default is `false`. */
        onlySuperAdminEdit: false,
    },
    // added via correction actions, should not be available in the dropdown
    corrected: {
        isHidden: () => true,
        dynamicDesc: (event) => {
            return `History Correction (${event.metadata?.corrections?.length} events - See details)`;
        },
        extendEventEdit: <CorrectedEventsTable/>,
        canSuperDelete: true,
        isPermanent: true,
    },

    newPotDiffJur: { histHidden: true, status: 'POT'} ,

    //Payments
    payAut: {groups: ['Payment']},
    paySus: {groups: ['Payment']},
    payEnd: {groups: ['Payment']},
    payRev: {groups: ['Payment']},
    payRei: {groups: ['Payment']},

     //Eligibility
    newPot: {
        isNewPotential: true,
        status: 'POT', 
        histHidden: true,
        isHidden: () => true,
        ...withPointersOnAdd
    },
    potRmb: {status : 'POT'},
    lgcyAct: { ...shared.active },
    enrRec: {
        groups: ['Eligibility'],
        shouldHideDate: true,
        showJoinDate: true,
        formReceived: true,
    },
    metElig: { ...shared.active, 
        ...withPointersOnAdd },
    nrolFday: { ...shared.active, 
        hideForJanOneWarning: true, 
        isFirstDayEvent: true,
        ...withPointersOnAdd
     },
    metEligDate: { status: 'ELI', ...shared.eligibility },
    erWaiv: { ...shared.active, hideForJanOneWarning: true },
    refIneg: { ...shared.ineligible, groups: ['Eligibility'] },
    ineg: { ...shared.ineligible, groups: ['Eligibility'] },
    ppExp: {status: 'CAN', groups: ['Eligibility']},
    retPay: {groups: ['Eligibility']},
    noRetP: {groups: ['Eligibility']},
    //Age60
    a60Des: {groups: ['Age60']},
    a60Der: {groups: ['Age60']},
    a60Sen: {groups: ['Age60']},
    a60Pen: {groups: ['Age60'], isRetirementEvent: true},
    a60Nop: {groups: ['Age60'], isNOP: true },
    a60Fdr: {status: 'PEN', groups: ['Age60']},
    a60sdf: {status: 'DFR',groups:['Age60']},
    a60oso: { isPackageSent: true, groups:['Age60'], includeInDesc: true },
    a60neg: { groups: ['Age60'], isNOP: true },
    //LTD60
    l60Ccp: {groups: ['LTD60']}, 
    l60Pen: {groups: ['LTD60']},
    l60Nop: {groups: ['LTD60'], isNOP: true },
    l60Fdr:  {status: 'PEN', groups: ['LTD60']},
    l60oso: { isPackageSent: true, groups: ['LTD60'], includeInDesc: true },
    //Retirement
    penPed: {groups: ['Retirement'], isMEMTD:true},
    penSus: {status: 'SUP', groups: ['Retirement']},
    penRea: {status: 'PEN', groups: ['Retirement']},
    penRap: {status: 'PEN', groups: ['Retirement']},
    penWan: {status: 'PEN', groups: ['Retirement']},
    penRac: {status: 'TRM', groups: ['Retirement']},
    retDef: {status: 'DFR', groups: ['Retirement'], singleEvent: true},
    rfdDoc: {status: 'DFR', groups: ['Retirement']},
    penOnp: {status: 'PEN', groups: ['Retirement']},
    penOpo: {status: 'PEN', groups: ['Retirement'], isPackageSent: true},
    //Termination
    trmPed: {groups: ['Termination'], isMEMTD: true},
    trmReq :{status: 'TRM', groups: ['Termination']},
    trmPre: {status: 'TRM', groups: ['Termination'], isPackageSent: true},
    trmPde: {status: 'TRM', groups: ['Termination'], isPackageSent: true, isHidden: () => true},
    trmOtn: {status: 'TRM', groups: ['Termination'], isPackageSent: true},
    trmPda: {status: 'TRM', groups: ['Termination'], isPackageSent: true},
    trmDef: {status: 'DFR', groups: ['Termination']},
    trmDea: {status: 'DFR', groups: ['Termination']},
    trmRef: {status: 'TRM', groups: ['Termination']},
    trmPao: {status: 'TRM', groups: ['Termination']},
    trmLit: {status: 'TRM', groups: ['Termination']},
    trmOnp: {status: 'TRM', groups: ['Termination']},
    //Death
    deaDac: {status: 'DED', groups: ['Death']},
    dapSurv: {status: 'DED', groups: ['Death']},
    deaDap: {status: 'DED', groups: ['Death']},
    deaEnd: {status: 'DED', groups: ['Death']},
    deaOnp: {status: 'DED', groups: ['Death']},
    deaOdo: {status: 'DED', groups: ['Death'], isPackageSent: true},

    //Pensioner
    rPenFrms: {groups: ['Rehiring a pensioner']},
    frzPenExp: {groups: ['Rehiring a pensioner']},
    rPenExpl: { ...shared.ineligible, groups: ['Rehiring a pensioner']},
    susPenEli: {
        next: (params) => {
            params.participation.membership.participations.filter(pp => pp.status.isReceivingPension() && pp.employments.last?.employer.plan.jurisdiction === params.participation.employments.last.employer.plan.jurisdiction)?.forEach(pp => {pp.addEvent({code:'susPen'})});
        } 
    },
    susPenNEG: {...shared.ineligible, groups: ['Rehiring a pensioner']},
    susPen: {status: 'SUP'},

    rPenFrmr: { groups: ['Rehiring a pensioner'] },
    penDea: { 
        isPendingEvent: true, 
        includeInDesc: true,
        shouldHideDate: true,
        groups: ['Death']
    },
    penTrm: { 
        canAdd: (params) => !params?.participation?.status?.isPotential(), //can be added if not potential
        isPendingEvent: true, 
        includeInDesc: true,
        shouldHideDate: true,
        groups: ['Termination']
    },
    penPnr: { 
        canAdd: (params) => !params?.participation?.status?.isPotential(), //can be added if not potential
        isPendingEvent: true, 
        includeInDesc: true,
        shouldHideDate: true,
        groups: ['Retirement']
    },
    deaFDC: { status: 'DED', groups: ['Death'] },
    pnrFDC: { status: 'PEN', groups: ['Retirement'] },
    termFDC: { status: 'TRM', groups: ['Termination'] },
    pRim: {
        status: 'TRM', 
        isReimbursment: true,
        groups: ['Eligibility']
    },

    midMonthAdj: {isHidden: () => true, histHidden: true},
    inegOvAge: {...shared.ineligible, groups: ['Eligibility']},
    inegNotF: {...shared.ineligible, groups: ['Eligibility']},
    inegRev: {...shared.ineligible, groups: ['Eligibility'],
        /** Only SuperAdmin users can add/edit this event. Value for `inegRev` is `true`. */
        onlySuperAdminEdit: true
    },
    inegDes: {...shared.ineligible, groups: ['Eligibility']},
    penRhdD: {...shared.ineligible, groups: ['Rehiring a pensioner']},
    penRhdA: {status: 'POT', groups: ['Rehiring a pensioner']},
    penEmp: {status: 'POT', groups: ['Age60'] },
    onbrd: {
        /**
         * 
         * @param {*} params 
         * @returns true if the event's sub-event tasks are all validated
         */
        canShow: (params) => {
            let tasks = params.event.subEvent.constructor.ref.getTaskList(params.instance, params.event);
            return tasks.length === tasks.filter(x=>x.validated).length;
        },
        showTask: OnboardingTask,
        flowKey: FLOW.ONBOARDING,
    },
    aElig: { isHidden: () => true, groups: ['Eligibility'], isAlreadyEligible: true, ...shared.eligibility },
    spouInf: { isHidden: () => true, histHidden: true, isSpouseCheck: true },
    latest: { isFollowUp: true },
    memRespRec: { 
        includeInDesc: true, 
        isHidden: ({employment}) => employment ? !employment.status?.isTerminated() : true,
        comment: `DATE: Response received: REF/DEF/LIT [INITIALS]
o Recalculation Required: NO/YES {If YES,…} | Requested: DATE [INITIALS] |  Received: DATE [INITIALS]
o Issue(s): NO/YES (if YES} : (i) {ex, missing form, conflicting/missing data;}
o Issue(s) resolved: YES: DATE: {explain resolution}
DATE: READY TO PROCESS`,
    },

    trfLtr:{ isHidden: () => true }, //Legacy
    nTrfLtr: { isHidden: () => true }, //Legacy
    trfDes: { isHidden: () => true }, //Legacy
    trfExpAut: { isHidden: () => true }, //Legacy
    trfInfEmp:{ isHidden: () => true }, //Legacy
}

