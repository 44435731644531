import StringInput from '../../../components/filters/StringInput';
import { Definition, Ref}  from '../../../framework/infra'
import RefFilter from '../../../framework/infra/model/RefFilter';
import CommonFilters from '../CommonFilters';
import DateRange from '../../../components/filters/DateRange';
import moment from 'moment';
import { ETS_FORMAT } from '../../../framework/utils/helper';
import SearchUtilModule from '../utils';

export default class EventView extends Ref {

    static get filters() {
        return EmploymentViewFilters;
    }

    static get exportConfigs() {
        return {
            fileName: 'EventExport-' + new Date().toISOString().slice(0, 10),
            sheetName: 'Employments',
            columns: ['employer.code', 'sin', 'nameOriginal', 'dateOfBirth', 'joinDateString']
        };
    }

    static updateDefinitions(customDefinitions) {
        this.definitions = { ...this.definitions, ...customDefinitions };
    }

    static definitions = {
        id: { type: Definition.types.STRING, text: 'ID' },
        nameOriginal: { type: Definition.types.STRING, text: 'Name' },
        sin: { type: Definition.types.SIN, text: 'SIN' },
        joinDate: { type: Definition.types.TIMESTAMP, text: 'Join Date' },
        joinDateString: { type: Definition.types.STRING, text: 'Join Date' },
        employmentStatusKey: { type: Definition.types.STRING, text: 'Employment Status Key' },
        statusEffectiveDate: { type: Definition.types.DATE, text: 'Status Effective Date' },
        participationStatusKey: { type: Definition.types.STRING, text: 'Participation Status Key' },
        baseMonthlyEarning: { type: Definition.types.AMOUNT, text: 'Base Monthly Earnings' },
        employeeNumber: { type: Definition.types.STRING, text: 'Employee #' },
        dateOfBirth: { type: Definition.types.DATE, text: 'Date of Birth' },
        employerId: { type: Definition.types.STRING, text: 'Employer ID' },
        personId: { type: Definition.types.STRING, text: 'Person ID' },
        cqpp: { type: Definition.types.BOOLEAN, text: 'CPP/QPP' },
        /** participation status events (events that change the status) */
        participationEvents: { isListRef: true, type: Event, text: 'Participation Events' },
        /** participation events (events that do not change the status) */
        participationNonStatusEvents: { isListRef: true, type: Event, text: 'Participation Non Status Events' },
        /** employment status events (events that change the status) */
        employmentEvents: { isListRef: true, type: Event, text: 'Employment Events' },

        //transients
        employer: { transient: true, ref: require('../../employment/Employer'), text: 'Employer' },

        //abstracts
        /** Description of participation status events (events that change the status) */
        participationEventDescription: { abstract: true, type: Definition.types.STRING, text: 'Participation Status At Period Start' },
        /** Description of employment status events (events that change the status) */
        employmentEventDescription: { abstract: true, type: Definition.types.STRING, text: 'Employment Status At Period End' },
        filters: { abstract: true, type: EmploymentViewFilters, text: 'Filters' }
    }
}

class Event extends Ref {
    static definitions = {
        code: { type: Definition.types.STRING, text: 'Code' },
        effectiveDate: { type: Definition.types.DATE, text: 'Effective Date' },
    }
}


export class EmploymentViewFilters extends RefFilter {

    constructor(data) {
        super(data, EmploymentViewFilters.definitions);
    }

    static definitions = {
        dataRange: {
            isFilter: true,
            type: Definition.types.DATE, 
            text: 'Date Range', 
            icon: 'calendar',
            limit: 1,
            component: <DateRange/>,
        },
        periodParticipationStatusKey: {...CommonFilters.participationStatusKey,
            /**
             * Format the value for the dynamic column.
             * 
             * Get the effective Date of the participationEvents.
             * @param {*} value 
             * @param {EventView} instance 
             * @param {string} attributeName
             * @returns The participationEvents effective date as "DD MMM YYYY"
             */
            formatDynamicColumnValue: (instance, attributeName) => {
                if (instance instanceof EventView && instance[attributeName]) {
                    const formattedValue = instance[attributeName].map(ev => moment(ev.effectiveDate).format(ETS_FORMAT)).join(', ')
                    return formattedValue;
                }
                return '';
            }, 
            /**
             * Filter the values for the dynamic column.
             * 
             * Find the events in `participationEvents` that matches the code and date range.
             * Set the attribute on the instance with the filtered events.
             * @param {*} value 
             * @param {EventView} instance 
             * @param {string} eventCode 
             * @param {number | undefined} eventDateFrom 
             * @param {number | undefined} eventDateTo 
             * @param {object} dynamicColumnConfig
             */
            filterDynamicColumnValue: (value, instance, eventCode, eventDateFrom, eventDateTo, dynamicColumnConfig) => {
                if (instance instanceof EventView) {
                    const eventsWithCodeAndRange = SearchUtilModule.setEventsToAndFromDates(instance.participationEvents ?? []);
                    const eventsMatchingRange = eventsWithCodeAndRange.filter(ev => ev.code === eventCode && SearchUtilModule.eventRangeOverlapsDateRange(ev, {from: eventDateFrom, to: eventDateTo}));
                    instance[`${dynamicColumnConfig.prefix}-${eventCode}`] = eventsMatchingRange;
                }
            }
        },
        periodParticipationNonStatusEventsKey: {...CommonFilters.participationNonStatusKey,
            /**
             * Format the value for the dynamic column
             * 
             * Get the effective Date of the participationNonStatusEvents.
             * @param {*} value 
             * @param {EventView} instance 
             * @param {string} attributeName
             * @returns The participationNonStatusEvents effective dates as "DD MMM YYYY"
             */
            formatDynamicColumnValue: (instance, attributeName) => {
                if (instance instanceof EventView && instance[attributeName]) {
                    const formattedValue = instance[attributeName].map(ev => moment(ev.effectiveDate).format(ETS_FORMAT)).join(', ')
                    return formattedValue;
                }
                return '';
            },
            /**
             * Filter the values for the dynamic column
             * 
             * Find the events in `participationNonStatusEvents` that matches the code and date range.
             * Set the attribute on the instance with the filtered events.
             * @param {*} value 
             * @param {EventView} instance 
             * @param {string} eventCode 
             * @param {number | undefined} eventDateFrom 
             * @param {number | undefined} eventDateTo 
             * @param {object} dynamicColumnConfig
             */
            filterDynamicColumnValue: (value, instance, eventCode, eventDateFrom, eventDateTo, dynamicColumnConfig) => {
                if (instance instanceof EventView) {
                    const eventsMatchingRange = instance.participationNonStatusEvents?.filter(ev => ev.code === eventCode &&
                        (!eventDateFrom || ev.effectiveDate >= eventDateFrom) && (!eventDateTo || ev.effectiveDate <= eventDateTo)
                    );
                    instance[`${dynamicColumnConfig.prefix}-${eventCode}`] = eventsMatchingRange;
                }
            }
        },
        periodEmploymentStatusKey: {...CommonFilters.employmentStatusKey,
            /**
             * Format the values for the dynamic column
             * 
             * Get the effective Date of the employmentEvents.
             * @param {EventView} instance 
             * @param {string} attributeName 
             * @returns The employmentEvents effective dates as "DD MMM YYYY"
             */
            formatDynamicColumnValue: (instance, attributeName) => {
                if (instance instanceof EventView && instance[attributeName]) {
                    const formattedValue = instance[attributeName].map(ev => moment(ev.effectiveDate).format(ETS_FORMAT)).join(', ')
                    return formattedValue;
                }
                return '';
            },
            /**
             * Filter the values for the dynamic column
             * 
             * Find the events in `employmentEvents` that matches the code and date range.
             * Set the attribute on the instance with the filtered events.
             * @param {*} value 
             * @param {EventView} instance 
             * @param {string} eventCode 
             * @param {number | undefined} eventDateFrom 
             * @param {number | undefined} eventDateTo 
             * @param {object} dynamicColumnConfig
             */
            filterDynamicColumnValue: (value, instance, eventCode, eventDateFrom, eventDateTo, dynamicColumnConfig) => {
                if (instance instanceof EventView) {
                    const eventsWithCodeAndRange = SearchUtilModule.setEventsToAndFromDates(instance.employmentEvents ?? []);
                    const eventsMatchingRange = eventsWithCodeAndRange.filter(ev => ev.code === eventCode && SearchUtilModule.eventRangeOverlapsDateRange(ev, {from: eventDateFrom, to: eventDateTo}));
                    instance[`${dynamicColumnConfig.prefix}-${eventCode}`] = eventsMatchingRange;
                }
            }
        },
        sin: CommonFilters.sin,
        name: CommonFilters.nameWithFuzziness,
        joinDateString: CommonFilters.joinDateString,
        isLastParticipation: { 
            isFilter: true,
            type: Definition.types.BOOLEAN, 
            text: 'Last Participation', 
            icon: 'user',
            getLabel: (instance) => { return 'Last Participation' },
        },
        cqpp: { 
            isFilter: true,
            type: Definition.types.CHOICE, 
            text: 'CPP/QPP', 
            icon: 'id-badge',
            limit: 1,
            component: <StringInput options={
                [
                    { key: 'true', value:'true', text: 'Yes', },
                    { key: 'false', value:'false', text: 'No', },
                ]
            } />,
            getLabel: (instance) => { 
                return instance.cqpp === 'true' ? 'Is CPP/QPP' : 'Not CPP/QPP'
            },
        },
        employerIds: CommonFilters.employerIds,
        dob: CommonFilters.dob,
        dobUnder: CommonFilters.dobUnder,
        dobOver: CommonFilters.dobOver,
        dateFrom: {
            hidden: true,
            isFilter: true,
            type: Definition.types.DATE,
            text: 'From',
            icon: 'calendar',
            limit: 1,
            getLabel: (instance) => { 
                return 'From: ' + moment(Number(instance.dateFrom)).format(ETS_FORMAT)
            },
            getValue: (instance) => { 
                const value = SearchUtilModule.isDateNumber(instance.dateFrom) ? Number.parseInt(instance.dateFrom) : instance.dateFrom;
                return moment(value).valueOf()
            },
        },
        dateTo: {
            hidden: true,
            isFilter: true,
            type: Definition.types.DATE,
            text: 'To',
            icon: 'calendar',
            limit: 1,
            getLabel: (instance) => { 
                return 'To: ' + moment(Number(instance.dateTo)).format(ETS_FORMAT)
            },
            getValue: (instance) => { 
                const value = SearchUtilModule.isDateNumber(instance.dateTo) ? Number.parseInt(instance.dateTo) : instance.dateTo;
                return moment(value).valueOf()
            },
        },
        fuzziness: CommonFilters.nameFuzziness
    }
}