import Definition from './Definition';
import RefMessage from './RefMessage'
import RefPointer from './RefPointer';

export const EVENT_SOURCE = {
    UNDEFINED: {key: 'und', text: 'Source not defined'},
    SYSTEM:  {key: 'sys', text: (e) => 'Event added by the APP', icon: 'laptop'},
    MANUAL:  {key: 'man', text: (e) => `Event added ${e.rur ? ` by ${e.rur}`:'.' }`, icon: 'user'},
    FILE:  {key: 'file', text: (e) => {
        const userDesc = e.rur ? ` by ${e.rur}`:'' ;
        const remittanceRef = e?.pointers?.all?.find?.(p => p.isRemittancePointer);
        const employerRef = e?.pointers?.all?.find?.(p => p.isEmploymentPointer);
        const employerDesc = employerRef ? ` of ${employerRef.instance.code}` : '';
        if(remittanceRef !== undefined){
            // remittanceRef.instanceKey: employerId_period
            const remittanceEmployerId = typeof remittanceRef.instanceKey === "string" ? remittanceRef.instanceKey.split("_")[0] : undefined;
            const remittancePeriod = typeof remittanceRef.instanceKey === "string" ? remittanceRef.instanceKey.split("_")[1] : undefined;
            const remittanceEmployerDesc = remittanceEmployerId && employerRef && remittanceEmployerId === employerRef.instance.id ? ` of ${employerRef.instance.code}` : '';
            const remittanceDesc = remittancePeriod ? ` of ${remittanceEmployerDesc}${remittancePeriod}` : '';
            return `Event triggered by an earnings change in remittance${remittanceDesc}${userDesc}.`;
        }
        
        return `Event added through file upload${employerDesc}${userDesc}.`
    }, icon: 'file'},
}
export default class RefEvent extends RefMessage {
    constructor(data) {
        super(data);
        if (data) {
            this.mts = data.mts ?? data.ets;
        }
    }
    
    static key = ['key', 'ets']

    static compareEvents = (eventA, eventB) => {
        let result = 0;
        if(eventA.config?.priority > eventB.config?.priority){
            result = 1;
        }else if(eventA.config?.priority < eventB.config?.priority){
            result = -1;
        }else if (eventA?.mts < eventB?.mts) {
            result = -1;
        } else if (eventA?.mts > eventB?.mts) {
            result = 1;
        } else { 
            result = eventA?.rts < eventB?.rts ? -1 : (eventA?.rts === eventB?.rts ? 0 : 1);
        }
        return result;
    }

    static sortByEffectiveDate = (a, b) => {
        let result = 0;

        if (a.effDt === b.effDt) {
            result = this.compareEvents(a, b);
        } else if (a.effDt < b.effDt) {
            result = -1;
        } else {
            result = 1;
        }
        return result;
    }

    static getSingleEventsInHiddenGroup = (eventParam, instance, configList) => {
        const hiddenGroupEvents = Object.entries(configList)
                .map(([key, config]) => ({ key, config }))
                .filter((configItem) =>configItem.config?.hiddenGroup === eventParam.config?.hiddenGroup);
        
        return eventParam.config?.singleEventInGroup && instance.events.filter((ev) => hiddenGroupEvents.map((e) => e.key).includes(ev.code));
    }

    get eventDesc() { 
        //multiple resolutions may have various completion messages
        if (this.subEvent?.all.length > 0) {
            let lastSubEvent = this.subEvent.last;
            if (lastSubEvent.config.completeMessage) {
                return this.config.showTask.desc + ' - ' + lastSubEvent.config.completeMessage;
            }
        }

        if (this.config.dynamicDesc) return this.config.dynamicDesc(this);

        return this.desc 
    }

    get sourceValue () { 
        for (const source in EVENT_SOURCE) {
            if(EVENT_SOURCE[source].key === this.source) {
                return EVENT_SOURCE[source];
            }
        } 
        return;
    }

    static definitions = {
        source: { type: Definition.types.CHOICE, text: 'Employment Source', options: [
            EVENT_SOURCE.UNDEFINED,
            EVENT_SOURCE.SYSTEM,
            EVENT_SOURCE.FILE,
            EVENT_SOURCE.MANUAL,
        ]}, 
        metadata: { text: 'Extra metadata' },
        pointers: { list: true, ref: RefPointer, text: 'Pointers' },
    }
}
